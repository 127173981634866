import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { T } from '@transifex/react';
import { PortalIcon } from '../../../../common/components/PortalIcon';
import { mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js';
import { history } from '../../../../common/history/history';
import {
  getCtxMappingDeleteRoute,
  getCtxMappingEditRoute,
} from '../../../routes/ContextMappingDrawerRoute';
import {
  SitecoreContextMappingType,
  SitecoreContextResourceType,
  SitecoreContextType,
  TenantType,
} from '../../../types/types';
import { EDGE_ENDPOINT_TYPES, PRODUCT_CODES } from '../../../utils';

export const contextMappingTableButtonsAccessor = (
  sitecoreContextId: string,
  sitecoreContextType: string,
  isAllowEdit: boolean,
) => (
  <ButtonGroup flexDir='row' justifyContent='flex-end' size='sm' width='full'>
    {!isAllowEdit && (
      <IconButton
        variant='ghostColorOnHover'
        colorScheme='danger'
        aria-label='edit context mapping'
        icon={<PortalIcon path={mdiPencilOutline} />}
        onClick={() =>
          history.push({
            pathname: getCtxMappingEditRoute(sitecoreContextId),
            search: history.location.search,
          })
        }
      />
    )}
    {sitecoreContextType === 'custom' && (
      <IconButton
        variant='ghostColorOnHover'
        colorScheme='danger'
        aria-label='delete context mapping'
        icon={<PortalIcon path={mdiTrashCanOutline} />}
        onClick={() =>
          history.push({
            pathname: getCtxMappingDeleteRoute(sitecoreContextId),
            search: history.location.search,
          })
        }
      />
    )}
  </ButtonGroup>
);

type GetTableOptions = {
  withXmCloudContext: boolean;
};

export const getTableOptions = ({ withXmCloudContext }: GetTableOptions) => ({
  columns: [
    {
      Header: <T _str='Context ID' />,
      accessor: 'sitecoreContextId',
      disableSortBy: true,
    },
    ...(withXmCloudContext
      ? [
          {
            Header: <T _str='Edge Endpoint' />,
            accessor: 'edgeEndpoint',
            disableSortBy: true,
          },
        ]
      : []),
    {
      Header: <T _str='Personalize Tenant' />,
      accessor: 'personalizeTenant',
      disableSortBy: true,
    },
    {
      Header: <T _str='Search Tenant' />,
      accessor: 'searchTenant',
      disableSortBy: true,
    },
    {
      id: 'context-mapping-table-buttons',
      Header: '',
      accessor: ({
        sitecoreContextId,
        type,
        personalizeTenant,
        searchTenant,
        edgeEndpoint,
      }: SitecoreContextMappingType) =>
        contextMappingTableButtonsAccessor(
          sitecoreContextId,
          type,
          withXmCloudContext
            ? personalizeTenant !== 'Not Set' &&
                searchTenant !== 'Not Set' &&
                edgeEndpoint !== 'Not Set'
            : personalizeTenant !== 'Not Set' && searchTenant !== 'Not Set',
        ),
      disableSortBy: true,
    },
  ],
});

export const mapPersonalizeTenant = (
  resource: SitecoreContextResourceType,
  tenantsData: TenantType[],
  personalizeResourceTypes: string[],
  edgeResourceTypes: string[],
): string => {
  const tenant = tenantsData.find(
    (tenant) =>
      tenant.id === resource.tenantId &&
      personalizeResourceTypes.includes(
        tenant.labels.ProductCode.toLowerCase(),
      ),
  );

  if (!tenant) {
    const xmCloudTenant = tenantsData.find(
      (tenant) =>
        tenant.id === resource.tenantId &&
        edgeResourceTypes.includes(tenant.labels.ProductCode.toLowerCase()),
    );

    if (!xmCloudTenant) return '';

    const cdpEmbeddedTenantId =
      xmCloudTenant.annotations?.['XMCloud.CDPEmbeddedTenantID'];
    const foundTenant = tenantsData.find(
      (tenant) => tenant.id === cdpEmbeddedTenantId,
    );
    return foundTenant ? foundTenant.displayName : '';
  }

  return tenant.displayName ?? '';
};

export const mapEdgeEndpoint = (
  resource: SitecoreContextResourceType,
  tenantsData: TenantType[],
  edgeResourceTypes: string[],
): string => {
  const tenant = tenantsData.find(
    (tenant) =>
      tenant.id === resource.tenantId &&
      edgeResourceTypes.includes(tenant.labels.ProductCode.toLowerCase()),
  );
  if (tenant) {
    const projectName = tenant.displayName;
    const hostType = resource?.properties.find(
      (prop) => prop.id === 'hostType',
    )?.value;
    if (hostType === 'origin') {
      return `${projectName} - ${EDGE_ENDPOINT_TYPES.PREVIEW}`;
    } else if (hostType === 'worker') {
      return `${projectName} - ${EDGE_ENDPOINT_TYPES.LIVE}`;
    }
  }
  return '';
};

export const mapSearchTenant = (
  resource: SitecoreContextResourceType,
  tenantsData: TenantType[],
  searchResourceType: string[],
): string => {
  const tenant = tenantsData.find(
    (tenant) =>
      tenant.id === resource.tenantId &&
      searchResourceType.includes(tenant.labels.ProductCode.toLowerCase()),
  );
  return tenant ? tenant.displayName : '';
};

export const generateContextsTableData = (
  contexts: SitecoreContextType[] = [],
  tenantsData: TenantType[] = [],
) => {
  if (!contexts.length && !tenantsData.length) return [];

  return contexts.map((context) => {
    let edgeEndpoint = 'Not Set';
    let personalizeTenant = 'Not Set';
    let searchTenant = 'Not Set';

    context.resources.forEach((resource) => {
      const resourceType = resource.type.toLowerCase();

      const personalizeType = [
        PRODUCT_CODES.PERSONALIZE,
        PRODUCT_CODES.CDP,
        PRODUCT_CODES.PERSONALIZE_EMBEDDED,
      ];
      const edgeType = [PRODUCT_CODES.EDGE, PRODUCT_CODES.XM_CLOUD];
      const searchType = [PRODUCT_CODES.SEARCH];

      if (personalizeType.includes(resourceType)) {
        const mappedPersonalizeTenant = mapPersonalizeTenant(
          resource,
          tenantsData,
          personalizeType,
          edgeType,
        );
        if (mappedPersonalizeTenant)
          personalizeTenant = mappedPersonalizeTenant;
      } else if (edgeType.includes(resourceType)) {
        const mappedEdgeEndpoint = mapEdgeEndpoint(
          resource,
          tenantsData,
          edgeType,
        );
        if (mappedEdgeEndpoint) edgeEndpoint = mappedEdgeEndpoint;
      } else if (searchType.includes(resourceType)) {
        const mappedSearchTenant = mapSearchTenant(
          resource,
          tenantsData,
          searchType,
        );
        if (mappedSearchTenant) searchTenant = mappedSearchTenant;
      }
    });

    return {
      sitecoreContextId: context.sitecoreContextId,
      organizationId: context.organizationId,
      type: context.type,
      accountId: context.accountId,
      edgeEndpoint,
      personalizeTenant,
      searchTenant,
    };
  });
};
