import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Text,
  AlertDialogBody,
  AlertDialogFooter,
  ButtonGroup,
  Button,
  Input,
  FormControl,
  useToast,
} from '@chakra-ui/react';
import { history } from 'features/common/history/history';
import React, { useRef, useState } from 'react';
import { ctxMappingRoute } from '../../routes/ContextMappingDrawerRoute';
import { T } from '@transifex/react';
import { useDeleteSitecoreContext } from '../../api/useDeleteSitecoreContext';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { t } from '@transifex/native';

const DeleteContextMappingModal = () => {
  const [inputValue, setInputValue] = useState('');
  const lessDestructiveRef = useRef(null);
  const { sitecoreContextId } = useParams<{ sitecoreContextId: string }>();
  const {
    mutate: deleteSitecoreContextMutation,
    isLoading: isLoadingDeleteRequest,
  } = useDeleteSitecoreContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  const onClose = () =>
    history.replace(`${ctxMappingRoute}${history.location.search}`);

  const onDelete = () => {
    deleteSitecoreContextMutation(
      { id: sitecoreContextId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('contexts/list');
          onClose();
          toast({
            description: t(`Context ID ${sitecoreContextId} deleted.`),
            status: 'success',
          });
        },
        onError: () => {
          toast({
            description: t(`Context ID ${sitecoreContextId} deletion failed.`),
            status: 'error',
          });
        },
      },
    );
  };

  const handleDelete = () => {
    if (inputValue === 'delete') {
      onDelete();
    }
  };
  return (
    <AlertDialog
      isOpen={true}
      onClose={onClose}
      leastDestructiveRef={lessDestructiveRef}
      size='xl'
      autoFocus={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent data-testid='delete-context-mapping-modal' gap={2}>
          <AlertDialogHeader>
            <T _str='Delete Context ID' />
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>Permanently delete Context ID {sitecoreContextId}?</Text>
            <Text>
              All apps and services using this Context ID will need to be
              updated with a new Context ID.
            </Text>
            <FormControl py={8}>
              <Text>Type "delete" to confirm</Text>
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                mt={2}
              />
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button
                variant='ghost'
                data-testid='delete-context-mapping-modal-cancel-btn'
                onClick={onClose}
                isDisabled={isLoadingDeleteRequest}
              >
                Cancel
              </Button>
              <Button
                colorScheme='danger'
                variant='solid'
                data-testid='delete-context-mapping-modal-delete-btn'
                onClick={handleDelete}
                isDisabled={inputValue !== 'delete' || isLoadingDeleteRequest}
              >
                Delete
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
export default DeleteContextMappingModal;
