import {
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Box,
  HStack,
  Select,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
} from '@chakra-ui/react';
import { T } from '@transifex/react';
import { AddEditContextMappingSelectOption } from '../../types/types';
import { ChevronDownIcon } from '@chakra-ui/icons';

type ContextMappingDrawerBodyPropTypes = {
  isWithXmCloudContext: boolean;
  values: any;
  edgeEndpointSelectOptions: AddEditContextMappingSelectOption[];
  cdpPersonalizeTenantSelectOptions: AddEditContextMappingSelectOption[];
  searchTenantSelectOptions: AddEditContextMappingSelectOption[];
  setFieldValue: any;
  isValid: boolean;
  dirty: boolean;
  isLoading: boolean;
  isSubmitButtonDisabled: () => boolean;
  onCloseDrawer: () => void;
};

const AddEditContextMappingDrawerBody = ({
  isWithXmCloudContext,
  values,
  edgeEndpointSelectOptions,
  cdpPersonalizeTenantSelectOptions,
  searchTenantSelectOptions,
  setFieldValue,
  isValid,
  dirty,
  isLoading,
  isSubmitButtonDisabled,
  onCloseDrawer,
}: ContextMappingDrawerBodyPropTypes) => {
  return (
    <>
      <DrawerBody>
        <Text mb={5} data-testid='add-edit-context-mapping-drawer-description'>
          Choose the tenants you want to link together with context mapping.
        </Text>
        {isWithXmCloudContext && (
          <FormControl py={2}>
            <FormLabel>
              <T _str='Edge endpoint' />
            </FormLabel>
            <Menu>
              <MenuButton
                as={Button}
                w='100%'
                bg='white'
                color='rgba(0,0,0,0.94)'
                cursor='default'
                border='1px solid'
                borderColor='rgba(0,0,0,0.43)'
                borderRadius='md'
                fontWeight='normal'
                rightIcon={<ChevronDownIcon />}
                textAlign='left'
                _hover={{ bg: 'white', borderColor: 'black' }}
                _active={{
                  bg: 'white',
                  borderColor: 'purple',
                  borderWidth: '3px',
                }}
                _focus={{ boxShadow: 'outline' }}
                data-testid='add-edit-context-mapping-drawer-edge-endpoint-select'
                isDisabled={values.isEdgeEndpointDisabled}
              >
                {values.selectedEdgeEndpointValue
                  ? edgeEndpointSelectOptions.find(
                      (opt) => opt.id === values.selectedEdgeEndpointValue,
                    )?.displayName
                  : 'Select'}
              </MenuButton>
              <MenuList rootProps={{ width: '100%' }}>
                {Object.entries(
                  edgeEndpointSelectOptions.reduce((acc, opt) => {
                    const group = opt.projectName ?? '';
                    if (!acc[group]) acc[group] = [];
                    acc[group].push(opt);
                    return acc;
                  }, {} as Record<string, AddEditContextMappingSelectOption[]>),
                ).map(([group, options]) => (
                  <Box key={group} p={2}>
                    <Text fontWeight='bold' fontSize='sm' color='gray.600'>
                      {group}
                    </Text>
                    {options.map((opt) => (
                      <MenuItem
                        width='100%'
                        key={opt.id}
                        onClick={() =>
                          setFieldValue('selectedEdgeEndpointValue', opt.id)
                        }
                        data-testid={`menu-item-${opt.id}`}
                      >
                        <HStack justifyContent='space-between' w='100%'>
                          <Text>{opt.environmentName}</Text>
                          <Box
                            bg='gray.200'
                            borderRadius='full'
                            px={2}
                            py={1}
                            fontSize='xs'
                          >
                            {opt.displayName.split(' - ').pop()}
                          </Box>
                        </HStack>
                      </MenuItem>
                    ))}
                  </Box>
                ))}
              </MenuList>
            </Menu>
          </FormControl>
        )}
        <FormControl py={2}>
          <FormLabel>
            <T _str='CDP/Personalize tenant' />
          </FormLabel>
          <Select
            name='selectedCdpPersonalizeTenantValue'
            onChange={(e) =>
              setFieldValue('selectedCdpPersonalizeTenantValue', e.target.value)
            }
            data-testid='add-edit-context-mapping-drawer-cdp-personalize-tenant-select'
            placeholder='Select'
            value={values.selectedCdpPersonalizeTenantValue}
            isDisabled={values.isCdpPersonalizeTenantDisabled}
          >
            {cdpPersonalizeTenantSelectOptions.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.displayName}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl py={2}>
          <FormLabel>
            <T _str='Search tenant' />
          </FormLabel>
          <Select
            name='selectedSearchTenantValue'
            onChange={(e) =>
              setFieldValue('selectedSearchTenantValue', e.target.value)
            }
            data-testid='add-edit-context-mapping-drawer-search-tenant-select'
            placeholder='Select'
            value={values.selectedSearchTenantValue}
            isDisabled={values.isSearchTenantDisabled}
          >
            {searchTenantSelectOptions.map((opt) => (
              <option key={opt.id} value={opt.id}>
                {opt.displayName}
              </option>
            ))}
          </Select>
        </FormControl>
      </DrawerBody>
      <DrawerFooter justifyContent='flex-end'>
        <ButtonGroup>
          <Button
            data-testid='add-edit-context-mapping-drawer-cancel-btn'
            type='button'
            variant='ghost'
            onClick={onCloseDrawer}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='solid'
            data-testid='add-edit-context-mapping-drawer-save-btn'
            isDisabled={!isValid || !dirty || isSubmitButtonDisabled()}
            isLoading={isLoading}
          >
            Save
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

export default AddEditContextMappingDrawerBody;
