/* eslint-disable no-console */
import { ctxMappingRoute } from '../../routes/ContextMappingDrawerRoute';
import { ITenant } from '@sitecore-ui/portal-singular';
import {
  AddEditContextMappingSelectOption,
  SitecoreContextResourceType,
  SitecoreContextType,
} from '../../types/types';
import { EDGE_ENDPOINT_TYPES, PRODUCT_CODES } from '../../utils';

export const mutateOptions = (
  queryClient: any,
  history: any,
  toast: any,
  successToastDescription: string,
  errorToastDescription: string,
) => ({
  onSuccess: (response: { data: { sitecoreContextId: string } }) => {
    const { sitecoreContextId } = response.data;
    queryClient.invalidateQueries('contexts/list');
    history.replace({
      pathname: ctxMappingRoute,
      search: history.location.search,
    });
    toast({
      description: successToastDescription.replace(
        '{sitecoreContextId}',
        sitecoreContextId,
      ),
      status: 'success',
    });
  },
  onError: (error: any) => {
    const errorMsg =
      error?.isAxiosError && error?.response
        ? {
            title: error.response.data.title,
            description: error.response.data.detail,
          }
        : { description: errorToastDescription };
    toast({ ...errorMsg, status: 'error' });
  },
});

export const findTenantId = (
  types: string[],
  contextData: SitecoreContextType | undefined,
  tenantsData: ITenant[] | undefined,
) => {
  if (!contextData || !tenantsData || tenantsData.length === 0) return '';
  const resource = contextData?.resources?.find((res) =>
    types.includes(res.type.toLowerCase()),
  );
  return resource &&
    tenantsData.some(
      (tenant) =>
        tenant.id === resource.tenantId &&
        types.includes(tenant.labels?.ProductCode?.toLowerCase()),
    )
    ? resource.tenantId
    : '';
};

export const findPersonalizeTenantId = (
  types: string[],
  contextData: SitecoreContextType | undefined,
  tenantsData: ITenant[] | undefined,
  edgeTypes: string[],
) => {
  if (!contextData || !tenantsData || tenantsData.length === 0) return '';
  const resource = contextData?.resources?.find((res) =>
    types.includes(res.type.toLowerCase()),
  );

  const result =
    resource &&
    tenantsData.some(
      (tenant) =>
        tenant.id === resource.tenantId &&
        types.includes(tenant.labels?.ProductCode?.toLowerCase()),
    )
      ? resource.tenantId
      : '';

  if (!result) {
    const xmCloudTenant = tenantsData.find(
      (tenant) =>
        edgeTypes.includes(tenant.labels?.ProductCode?.toLowerCase()) &&
        tenant.id === resource?.tenantId,
    );

    if (xmCloudTenant) {
      const cdpEmbeddedTenantId =
        xmCloudTenant.annotations?.['XMCloud.CDPEmbeddedTenantID'];
      if (cdpEmbeddedTenantId) {
        const personalizeEmbeddedTenant = tenantsData.find(
          (tenant) =>
            types.includes(tenant.labels?.ProductCode?.toLowerCase()) &&
            tenant.id === cdpEmbeddedTenantId,
        );
        return personalizeEmbeddedTenant ? personalizeEmbeddedTenant.id : '';
      }
    }
  }

  return result;
};

export const findEdgeTenantId = (
  types: string[],
  contextData: SitecoreContextType | undefined,
  tenantsData: ITenant[] | undefined,
): string => {
  if (!contextData || !tenantsData || tenantsData.length === 0) return '';

  const resource = contextData.resources?.find((res) =>
    types.includes(res.type.toLowerCase()),
  );
  if (
    !resource ||
    !resource?.properties ||
    !tenantsData.some((tenant) => tenant.id === resource.tenantId)
  )
    return '';

  const hostTypeProperty = resource.properties?.find(
    (prop) => prop.id === 'hostType',
  );
  if (!hostTypeProperty) return '';

  const tenant = tenantsData.find((tenant) => tenant.id === resource.tenantId);
  if (!tenant) return '';

  return hostTypeProperty.value === 'origin'
    ? `${resource.tenantId} - ${EDGE_ENDPOINT_TYPES.PREVIEW}`
    : hostTypeProperty.value === 'worker'
    ? `${resource.tenantId} - ${EDGE_ENDPOINT_TYPES.LIVE}`
    : '';
};

export const findTenantDataByTenantId = (
  tenantId: string,
  tenantsData: ITenant[] | undefined,
): ITenant | undefined => {
  if (!tenantsData || tenantsData.length === 0) return undefined;
  return (
    tenantsData.find((tenant: ITenant) => tenant.id === tenantId) || undefined
  );
};

export const findContextResourceDataByTenantIdAndResourceType = (
  tenantId: string,
  resourceType: string,
  contextsData: SitecoreContextType[] | undefined,
): SitecoreContextResourceType | undefined => {
  if (!contextsData || contextsData.length === 0) return undefined;
  const tenantSplit = tenantId.split(' - ');
  const tenantIdToSearch = tenantSplit.slice(0, -1).join(' - ');
  const tenantIdHostType = tenantSplit[tenantSplit.length - 1];

  const context = contextsData.find((context) =>
    context.resources.some(
      (resource) =>
        resource.type.toLowerCase() === resourceType &&
        resource.tenantId === tenantIdToSearch &&
        resource.properties?.some(
          (prop) =>
            prop.id === 'hostType' &&
            ((tenantIdHostType === EDGE_ENDPOINT_TYPES.PREVIEW &&
              prop.value === 'origin') ||
              (tenantIdHostType === EDGE_ENDPOINT_TYPES.LIVE &&
                prop.value === 'worker')),
        ),
    ),
  );
  return (
    context?.resources.find(
      (resource: { type: string; tenantId: string }) =>
        resource.type.toLowerCase() === resourceType &&
        resource.tenantId === tenantIdToSearch,
    ) || undefined
  );
};

export const getSelectOptions = (
  tenantsData: ITenant[] | undefined,
  productCodes: string[],
): AddEditContextMappingSelectOption[] => {
  if (!tenantsData || tenantsData.length === 0) return [];
  return (
    tenantsData
      .filter((tenant: any) =>
        productCodes.includes(tenant.labels?.ProductCode?.toLowerCase()),
      )
      .map((tenant: any) => ({
        displayName: tenant.displayName,
        id: tenant.id,
      })) || []
  );
};

export const getSelectEdgeEndpointOptions = (
  contextsData: SitecoreContextType[] | undefined,
  tenantsData: ITenant[] | undefined,
) => {
  if (
    !contextsData ||
    contextsData.length === 0 ||
    !tenantsData ||
    tenantsData.length === 0
  )
    return [];

  const tenants = tenantsData.filter((tenant) =>
    contextsData.some((context) =>
      context.resources?.some(
        (resource) =>
          resource.type?.toLowerCase() === PRODUCT_CODES.EDGE &&
          resource.tenantId === tenant.id,
      ),
    ),
  );

  return tenants.flatMap((tenant) => [
    {
      displayName: `${tenant.displayName} - ${EDGE_ENDPOINT_TYPES.LIVE}`,
      id: `${tenant.id} - ${EDGE_ENDPOINT_TYPES.LIVE}`,
      projectName: tenant.annotations?.['XMCloud.ProjectName'],
      environmentName: tenant.annotations?.['XMCloud.EnvironmentName'],
    },
    {
      displayName: `${tenant.displayName} - ${EDGE_ENDPOINT_TYPES.PREVIEW}`,
      id: `${tenant.id} - ${EDGE_ENDPOINT_TYPES.PREVIEW}`,
      projectName: tenant.annotations?.['XMCloud.ProjectName'],
      environmentName: tenant.annotations?.['XMCloud.EnvironmentName'],
    },
  ]);
};

export const getContextCdpPersonalizeResource = (
  tenantId: string,
  tenantData: ITenant | undefined,
) => ({
  id: 'resource-cdp',
  name: 'Sitecore Personalize',
  type: PRODUCT_CODES.PERSONALIZE,
  tenantId,
  properties: [
    { id: 'region', value: String(tenantData?.labels['RegionCode'] || '') },
    { id: 'client_key', value: String(tenantData?.labels['clientKey'] || '') },
  ],
});

export const getContextSearchResource = (
  tenantId: string,
  tenantData: ITenant | undefined,
) => ({
  id: 'resource-search',
  name: 'Sitecore Search',
  type: PRODUCT_CODES.SEARCH,
  tenantId,
  properties: [
    { id: 'domain', value: String(tenantData?.labels['domain_id'] || '') },
    { id: 'apiKey', value: String(tenantData?.annotations['ckey'] || '') },
    {
      id: 'region',
      value: String(tenantData?.annotations['TenantClaims.region'] || ''),
    },
  ],
});
